import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      
      {
        color: 'primary',
        label: "جديدة",
        id:0
      },
      {
        color: 'success',
        label: 'مقيولة',
        id:1
      },
      {
        color: 'danger',
        label: 'مرفوضة',
        id:2
      },
      
    ],
    selectedCalendars: [1,0, 2],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx,queryParams) {
      //console.log(queryParams)
      let params=[]
      queryParams.status.forEach(el => {
        params.push(el)
        
      });
      return new Promise((resolve, reject) => {
        //&user=${queryParams.user}
        axios
          .get(`/api/v1/leave?status=${JSON.stringify(params)}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getLeaveType(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/leave-types')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/leave',data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEventReplay(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/leave-reply`,data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, data ) {
      //console.log('da',data)
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/leave/${data.id}`,{
            status:data.status
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    responseByEmployee(ctx,{id,assigner_approve}) {
      return new Promise((resolve, reject) => {


      let url=`api/v1/leave-assign-approve/${id}`
        axios
          .put(url,{assigner_approve:assigner_approve})
          .then((response) => {

            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchListLeaverApprove(ctx,queryParams) {
      return new Promise((resolve, reject) => {

        axios
          .get(`/api/v1/leave-needs-approve`,{params:queryParams})
          .then(response =>

            resolve(response.data)
            )
          .catch(error => reject(error))
      })

    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
